<template>
  <div>
    <div v-if="model.length == 0">
      <b-alert show variant="light">
        {{ $t('SETUP.COMMUNITY_OPTIONS.NO_FORUMS') }}
      </b-alert>
    </div>
    <div v-else>
      <div class="segment-title d-flex py-5">
        <span class="fix-width w-50 px-5">{{
          $t("SETUP.COMMUNITY_OPTIONS.FORUM_TOPIC")
        }}</span>
        <span class="fix-width w-20 px-10">{{
          $t("TABLES.PROJECT_FORUMS.SOCIAL_SETTING")
        }}</span>
        <span class="fix-width w-20 px-8">{{
          $t("TABLES.PROJECT_FORUMS.MODIFIED")
        }}</span>
      </div>
      <b-form-group
        id="input-project-forums-group"
        class="col-xl-12 px-0"
      >
        <li
          class="list-group-item d-flex justify-content-between align-items-center"
          v-for="(element, idx) in model"
          :key="idx"
        >
          <template>
            <div class="w-50">
              <router-link
                class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
                :to="{
                  name: `edit_project_forum`,
                  params: {
                    projectForum: element.id
                  }
                }"
              >
                {{ element.topic | stripHTML }}
              </router-link>
            </div>
            <div class="w-20">
              <span class="px-10">
                {{ element.system_social_setting.name }}
              </span>
            </div>
            <div class="w-20">
              <span class="px-10">
                <GenericDatetimeDisplay
                  :long="true"
                  :date="element.updated_at"
                  :lastUpdated="true"
                  :relative="false"
                >
                </GenericDatetimeDisplay>
              </span>
            </div>
            <div class="button-float-right">
              <a
                href="javascript:void(0)"
                @click="deleteForum(element)"
              >
                <i
                  v-if="element.required == 0"
                  class="fa fa-times close pl-10 py-0 font-size-base"
                  @click="deleteForum(element)"
                />
              </a>
            </div>
          </template>
        </li>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"

import {
  DELETE_PROJECT_FORUM,
} from "@/core/services/store/project/project_forums.module";

export default {
  name: "ProjectForums",
  props: {
    model: { type: Array, required: true },
  },
  components: {
    GenericDatetimeDisplay
  },
  methods: {
    deleteForum(element) {
      this.$swal
        .fire({
          title: `${this.$t("SWEETALERTS.FORUM_DELETE_CHALLENGE.TITLE")}`,
          text: `${this.$t("SWEETALERTS.FORUM_DELETE_CHALLENGE.MESSAGE")}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `${this.$t(
            "SWEETALERTS.FORUM_DELETE_CHALLENGE.CONFIRM"
          )}`,
          cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`
        })
        .then(result => {
          if (result.isConfirmed) {
            let payload = {
              projectId: element.project_id,
              id: element.id
            };

            this.$store
              .dispatch(DELETE_PROJECT_FORUM, payload)
              .then(() => {
                this.$emit('forum_deleted');
              })
              .catch(() => {
                this.$swal.fire({
                  title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
                  text: `${this.$t(
                    "SWEETALERTS.FORUM_DELETE_FAIL.MESSAGE"
                  )}`,
                  icon: "error",
                  confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
                });
              });
          }
        });
    }
  }
};
</script>
<style scoped>
.text,
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}

.button-float-right {
  margin-left: auto;
  order: 2;
  float: right;
}
.close {
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 900 !important;
}

input {
  display: inline-block;
  width: 50%;
}
</style>
