<template>
  <div>
    <div ref="body" v-show="false">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import tippy from "tippy.js";
import "tippy.js/themes/light-border.css";
import 'tippy.js/animations/shift-toward.css';

export default {
  props: {
    name: {}
  },

  mounted() {
    document
      .querySelectorAll(`[data-tooltip-name=${this.name}]`)
      .forEach(elem => {
        new tippy(elem, {
          getReferenceClientRect: () => ({
            width: 1000,
            height: 100,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }),
          theme: "light-border",
          trigger: "click",
          animation: "shift-toward",
          delay: 100,
          duration: [500, 200],
          content: this.$refs.body.innerHTML,
          allowHTML: true
        });
      });
  }
};
</script>
