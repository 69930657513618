<template>
  <div>
    <form @submit.prevent class="form" id="community-options">
      <tooltip name="community-updates-example-tooltip">
        <img
          src="https://picsum.photos/1280/720?rrandom=1"
        />
        <!--Here be dragons, link to picture example of community updates-->
      </tooltip>
      <tooltip name="forums-example-tooltip">
        <img
          src="https://picsum.photos/1280/720?rrandom=2"
        />
        <!--Here be dragons, link to picture example of forums-->
      </tooltip>
      <div class="card card-custom card-stretch gutter-b bordered-card-primary">
        <div class="card-header border-0 py-5">
          <div class="card-title d-flex justify-content-between w-100">
            <div>
              <h6> 
                {{ $t("SETUP.COMMUNITY_OPTIONS.COMMUNITY_OPTIONS") }}
              </h6>
            </div>
            <div class="justify-content-end">
              <!-- <span class="btn btn-secondary mr-5" data-tooltip-name="community-updates-example-tooltip">
                  {{ $t("SETUP.COMMUNITY_OPTIONS.COMMUNITY_UPDATES_EXAMPLE") }}
              </span>
              <span class="btn btn-secondary" data-tooltip-name="forums-example-tooltip">
                  {{ $t("SETUP.COMMUNITY_OPTIONS.FORUMS_EXAMPLE") }}
              </span> -->
            </div>
          </div>
          <p>{{ $t("SETUP.COMMUNITY_OPTIONS.COMMUNITY_OPTIONS_DESCRIPTION") }}</p>
        </div>
        <div class="card-body">
          <b-alert v-if="error.active" show variant="danger">
            {{ error.message }}
          </b-alert>
          <b-spinner
            v-if="loadingComplete == false && error.active == false"
            variant="primary"
            label="Spinning"
            class="card-loader"
          >
          </b-spinner>
          <div v-if="loadingComplete" class="col-xl-8 px-0">
            <!-- <InputGenericCheckbox
              id="input-community-updates"
              :label="$t('SETUP.COMMUNITY_OPTIONS.COMMUNITY_UPDATES')"
              :model="$v.form.communityUpdates.value"
              class="mb-5"
              @valueChanged="onSubmit(form.communityUpdates.id,form.communityUpdates.value)"
              v-b-tooltip.hover.right
              :title="$t('SETUP.COMMUNITY_OPTIONS.COMMUNITY_UPDATES_TOOLTIP')"
              style="width:250px; height:20px"
              :submissionStates="submissionStates"
            >
            </InputGenericCheckbox>
            <InputGenericCheckbox
              id="input-members"
              :label="$t('SETUP.COMMUNITY_OPTIONS.MEMBERS')"
              :model="$v.form.members.value"
              class="mb-5"
              @valueChanged="onSubmit(form.members.id,form.members.value)"
              v-b-tooltip.hover.right
              :title="$t('SETUP.COMMUNITY_OPTIONS.MEMBERS_TOOLTIP')"
              style="width:250px; height:20px"
              :submissionStates="submissionStates"
            >
            </InputGenericCheckbox>
            <InputGenericCheckbox
              id="input-moderation"
              :label="$t('SETUP.COMMUNITY_OPTIONS.MODERATION')"
              :model="$v.form.moderation.value"
              class="mb-5"
              @valueChanged="onSubmit(form.moderation.id,form.moderation.value)"
              v-b-tooltip.hover.right
              :title="$t('SETUP.COMMUNITY_OPTIONS.MODERATION_TOOLTIP')"
              style="width:250px; height:20px"
              :submissionStates="submissionStates"
            >
            </InputGenericCheckbox>
            <div v-if="form.moderation.value == 1">
              <div class="row px-4 mt-10 mb-1">
                <h6> 
                  {{ $t("SETUP.COMMUNITY_OPTIONS.MODERATION_TITLE") }}
                </h6>
              </div>
              <div class="row px-4 mb-2">
                <p>{{ $t("SETUP.COMMUNITY_OPTIONS.MODERATION_DESCRIPTION") }}</p>
              </div>
              <div class="row px-4 mb-10">
                <button class="btn btn-primary" @click="onOpenModerationModal"> {{ $t("SETUP.COMMUNITY_OPTIONS.EDIT_WATCHLIST") }} </button>
              </div>
            </div> -->
            <InputGenericCheckbox
              id="input-forums"
              :label="$t('SETUP.COMMUNITY_OPTIONS.FORUMS')"
              :model="$v.form.forums.value"
              class="mb-5"
              @valueChanged="onSubmit(form.forums.id,form.forums.value)"
              v-b-tooltip.hover.right
              :title="$t('SETUP.COMMUNITY_OPTIONS.FORUMS_TOOLTIP')"
              style="width:250px; height:20px"
              :submissionStates="submissionStates"
            >
            </InputGenericCheckbox>
            <div v-if="form.forums.value == 1">
              <div class="row px-4 mt-10 mb-1">
                <h6> 
                  {{ $t("SETUP.COMMUNITY_OPTIONS.FORUMS_TITLE") }}
                </h6>
              </div>
              <div class="row px-4 mb-2">
                <p>{{ $t("SETUP.COMMUNITY_OPTIONS.FORUMS_DESCRIPTION") }}</p>
              </div>
              <div class="row">
                <InputGenericTextArea
                  class="col-xl-12 px-4 mb-5"
                  id="input-forums-introduction"
                  :label="$t('SETUP.COMMUNITY_OPTIONS.FORUMS_INTRODUCTION')"
                  @valueChanged="textAreaChanged"
                  :model="$v.form.forum_introduction.value"
                  :submissionStates="submissionStates"
                >
                </InputGenericTextArea>
              </div>
              <div class="row px-4 mb-10">
                <button class="btn btn-primary" @click="onOpenForumModal"> {{ $t("SETUP.COMMUNITY_OPTIONS.ADD_TOPIC") }} </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form.forums.value == 1" class="card card-custom card-stretch gutter-b bordered-card-primary">
          <div class="card-header border-0 py-5">
            <div class="card-title d-flex justify-content-between w-100">
              <div>
                <h6 class="pt-3"> 
                  {{ $t("SETUP.COMMUNITY_OPTIONS.FORUM_TOPICS") }}
                </h6>
              </div>
            </div>
          </div>
          <div class="card-body">
            <b-spinner
              v-if="loadingComplete == false"
              variant="primary"
              label="Spinning"
              class="card-loader"
            >
            </b-spinner>
            <div class="col-xl-12" v-if="loadingComplete">
              <div class="row">
                <ProjectForums
                  class="col-xl-12 px-5 mb-10"
                  @forum_deleted="forumDeleted"
                  :model="projectForumList"
                  :submissionStates="submissionStates"
                ></ProjectForums>
              </div>
            </div>
          </div>
        </div>
    </form>
    <b-modal ref="forum-modal" size="xl" hide-footer :title="$t('SETUP.COMMUNITY_OPTIONS.ADD_FORUM')">
      <div
        id="create-forum"
        style="box-shadow: none !important"
      >
        <div class="row">
          <InputActivityTitle
            class="col-xl-12 px-5 mb-10"
            :model="$v.forum.topic"
            :submissionStates="submissionStates"
            :label="$t('SETUP.COMMUNITY_OPTIONS.FORUM_TOPIC')"
          ></InputActivityTitle>
        </div>
        <div class="row">
          <label
            id="input-forum-description-group_label"
            for="input-forum-description"
            class="d-block ml-5"
          >
            {{ $t('SETUP.COMMUNITY_OPTIONS.FORUMS_DESCRIPTION') }}*
          </label>
          <textarea
            id="input-forum-description"
            v-model="$v.forum.description.$model"
            class="form-control form-control-solid mx-5 mb-10"
            rows="6"
          >
          </textarea>
        </div>
        <div class="row">
          <InputGenericRadioOption
            :model="$v.forum.system_social_setting_id"
            class="col-xl-4 px-5"
            :submissionStates="submissionStates"
            id="input-forum-social-setting"
            :label="$t('SETUP.COMMUNITY_OPTIONS.FORUM_SOCIAL_SETTING')"
            :error="$t('FORMS.ERROR.FORUM_SOCIAL_SETTING')"
            :options="[
              {
                value: 1,
                text: $t('FORMS.INPUTS.ACTIVITY_OPEN'),
                tooltip: $t('ACTIVITY.TOOLTIPS.OPEN')
              },
              {
                value: 2,
                text: $t('FORMS.INPUTS.ACTIVITY_IN_SEGMENT'),
                tooltip: $t('ACTIVITY.TOOLTIPS.IN_SEGMENT')
              }
            ]"
          >
          </InputGenericRadioOption>
        </div>
        <div class="row">
          <!-- Here be dragons - submissionStates manually set to false for now -->
          <InputAdvancedSegmentSelect
            id="input-segments"
            class="px-5 mb-5 align:right"
            :submissionStates="{ submitting: false }"
            children_key="project_segments"
            label_key="name"
            :options="projectSegmentations"
            :initialSelection="initial_selection"
            @children_changed="forum.segments = $event"
            @clear_segments="forum.segments = []"
            @initial_selection_changed="initial_selection = $event"
          >
          </InputAdvancedSegmentSelect>
        </div>
        <div class="row">
          <div class="card-footer d-flex justify-content-between my-0 py-4 w-100">
            <div></div>
              <div class="justify-content-end">
                <button
                  @click="onAddForum()"
                  class="btn btn-primary mr-2"
                >
                  {{ $t("SETUP.COMMUNITY_OPTIONS.ADD_TOPIC") }}
                </button>
              </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal ref="moderation-modal" size="lg" hide-footer :title="$t('SETUP.COMMUNITY_OPTIONS.WATCHLIST')">
      <div
        id="edit-watchlist"
        style="box-shadow: none !important"
      >
        <div class="row">
          <div class="card-title px-2">
            {{ $t("SETUP.COMMUNITY_OPTIONS.MODERATED_WORDS_DESCRIPTION") }}
          </div>
        </div>
        <div class="row">
          <vue-tags-input
            id="tag-input"
            v-model="moderated_words.word"
            :tags="moderated_words.words"
            @before-adding-tag="postWord"
            @before-deleting-tag="deleteWord"
            @tags-changed="newWords => moderated_words.words = newWords"
            placeholder="Input your moderated words"
            class="form-control form-control-solid h-auto py-0 px-0 w-100"
            :separators="separators"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import VueTagsInput from  "@sergor5/vue-tags-input";

import { POPULATE_PROJECT_SEGMENTATION_LIST } from "@/core/services/store/project/project_segmentations.module";
import {
  POPULATE_PROJECT_COMMUNITY_OPTION_LIST,
  UPDATE_PROJECT_COMMUNITY_OPTION
} from "@/core/services/store/project/project_community_options.module";
import {
  POPULATE_PROJECT_FORUM_LIST,
  CREATE_PROJECT_FORUM
} from "@/core/services/store/project/project_forums.module";
import {
  POPULATE_PROJECT_MODERATED_WORD_LIST,
  CREATE_PROJECT_MODERATED_WORD,
  DELETE_PROJECT_MODERATED_WORD
} from "@/core/services/store/project/project_moderated_words.module";

import InputGenericCheckbox from "@/modules/together-helpers/components/generic-inputs/GenericCheckbox";
import InputGenericTextArea from "@/modules/together-helpers/components/generic-inputs/GenericTextArea";
import InputActivityTitle from "@/view/components/form-inputs/ActivityTitle";
import InputGenericRadioOption from "@/view/components/form-inputs/GenericRadioOption";
import InputAdvancedSegmentSelect from "@/view/components/form-inputs/AdvancedSegmentSelect";
import ProjectForums from "@/view/components/form-inputs/ProjectForums";
import Tooltip from "@/view/components/Tooltip.vue";

export default {
  mixins: [validationMixin],

  name: "CommunityOptions",

  components: {
    InputGenericCheckbox,
    Tooltip,
    InputGenericTextArea,
    InputGenericRadioOption,
    InputActivityTitle,
    InputAdvancedSegmentSelect,
    ProjectForums,
    VueTagsInput
  },
  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 4
      },

      error: {
        active: false,
        message: ""
      },

      submissionStates: {
        submitting: false
      },

      initial_selection: [],
      newWords: [],
      separators: [';',','],

      form: {
        communityUpdates: {},
        members: {},
        moderation: {},
        forums: {},
        forum_introduction: {},
      },

      forum: {
        segments: [],
        topic: null,
        description: null,
        system_social_setting_id: 1
      },

      moderated_words: {
        word: "",
        words: []
      }
    };
  },

  validations: {
    form: {
      communityUpdates: {
        value: {
          required
        }
      },
      forums: {
        value: {
          required
        }
      },
      members: {
        value: {
          required
        }
      },
      moderation: {
        value: {
          required
        }
      },
      forum_introduction: {
        value: {}
      }
    },
    forum: {
      segments: {},
      topic: {
        required
      },
      description: {
        required
      },
      system_social_setting_id: {
        required
      }
    }
  },

  mounted() {
    this.fetchProjectSegmentations();
    this.fetchForums();
    this.fetchModeratedWords();
    this.prepopulateInputs();
  },

  methods: {
    prepopulateInputs() {
      let payload = {
        projectId: this.projectInfo.id
      };

      this.$store
        .dispatch(POPULATE_PROJECT_COMMUNITY_OPTION_LIST, payload)
        .then(() => {
          this.form.communityUpdates = {
            id: this.projectCommunityOptionList.find(o => o.config_key === 'Enable Community Updates').id,
            value: this.configValue(this.projectCommunityOptionList.find(o => o.config_key === 'Enable Community Updates').config_value)
          }
          
          this.form.members = {
            id: this.projectCommunityOptionList.find(o => o.config_key === 'Enable Members').id,
            value: this.configValue(this.projectCommunityOptionList.find(o => o.config_key === 'Enable Members').config_value)
          }
          
          this.form.moderation = {
            id: this.projectCommunityOptionList.find(o => o.config_key === 'Enable Moderation').id,
            value: this.configValue(this.projectCommunityOptionList.find(o => o.config_key === 'Enable Moderation').config_value)
          }
          
          this.form.forums = {
            id: this.projectCommunityOptionList.find(o => o.config_key === 'Enable Forums').id,
            value: this.configValue(this.projectCommunityOptionList.find(o => o.config_key === 'Enable Forums').config_value)
          }

          this.form.forum_introduction = {
            id: this.projectCommunityOptionList.find(o => o.config_key === 'Forum Introduction').id,
            value: this.projectCommunityOptionList.find(o => o.config_key === 'Forum Introduction').config_value
          }

          this.pageLoader.componentsCompleted++
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            `${this.$t("SETUP.COMMUNITY_OPTIONS.LOAD_ERROR")}`;
        });
    },
    fetchForums() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          systemSocialSetting: "with",
          projectSegments: "with"
        }
      };

      this.$store
        .dispatch(POPULATE_PROJECT_FORUM_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message = 
            `${this.$t("SETUP.COMMUNITY_OPTIONS.LOAD_ERROR")}`;
        });
    },
    fetchModeratedWords() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: "false"
        }
      };

      this.$store
        .dispatch(POPULATE_PROJECT_MODERATED_WORD_LIST, payload)
        .then(() => {
          this.projectModeratedWordList.forEach(element => {
            this.moderated_words.words.push({text: element.word, id: element.id})
          });
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message = 
            `${this.$t("SETUP.COMMUNITY_OPTIONS.LOAD_ERROR")}`;
        });
    },
    fetchProjectSegmentations() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          projectSegments: "with",
          paginate: false
        }
      };
     
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENTATION_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segmentations list, please check back later or contact the helpdesk";
        });
    },
    onSubmit: function(configId, configValue) {
      this.submissionStates.submitting = true;
      
      if (configValue == "1") {
        configValue = "true";
      } else if (configValue == "0") {
        configValue = "false";
      };

      let payload = {
        projectId: this.projectInfo.id,
        id: configId,
        data: {
          config_value: configValue
        }
      };

      this.$store.dispatch(UPDATE_PROJECT_COMMUNITY_OPTION, payload).then(() => {
        this.submissionStates.submitting = false;
      })
    },
    onOpenModerationModal() {
      this.$refs["moderation-modal"].show();
    },
    onOpenForumModal() {
      this.$refs["forum-modal"].show();
    },
    onAddForum() {
      this.$v.forum.$touch();
      if (this.$v.forum.$anyError) {
        return;
      }

      this.submissionStates.submitting = true;

      let payload = {
        projectId: this.projectInfo.id,
        data: this.forum
      };

      this.$store.dispatch(CREATE_PROJECT_FORUM, payload).
        then(() => {
          this.pageLoader.componentsRequired++;
          this.fetchForums();
          this.$refs["forum-modal"].hide();
          this.makeToast("success", "create_forum");
          this.forum.topic = null
          // reset validation on the topic
          this.$v.forum.topic.$reset()
          this.forum.segments = [];
          this.forum.system_social_setting_id = 1;
          this.submissionStates.submitting = false;
        })
    },
    forumDeleted() {
      this.pageLoader.componentsRequired++;
      this.fetchForums();
    },
    isDuplicate(words, word) {
      return words.map(w => w.text).indexOf(word) !== -1;
    },
    postWord(obj) {
      if (!this.isDuplicate(this.moderated_words.words,obj.tag.text) && this.submissionStates.submitting == false) {
        this.submissionStates.submitting = true;

        let payload = {
          projectId: this.projectInfo.id,
          data: {
            word: obj.tag.text
          }
        }

        this.$store.dispatch(CREATE_PROJECT_MODERATED_WORD, payload).then((res) => {
          obj.tag.id = res.id;
          obj.addTag();
          this.submissionStates.submitting = false;
        });
      }
    },
    deleteWord(obj) {
      if (this.submissionStates.submitting == false) {
        this.submissionStates.submitting = true;

        let payload = {
          projectId: this.projectInfo.id,
          id: obj.tag.id
        }

        this.$store.dispatch(DELETE_PROJECT_MODERATED_WORD, payload).then(() => {
          obj.deleteTag();
          this.submissionStates.submitting = false;
        });
      }
    },
    onWordsChanged(newWords) {
      this.moderated_words.words = newWords;
    },
    textAreaChanged() {
      this.onSubmit(this.form.forum_introduction.id,this.form.forum_introduction.value);
      this.makeToast("success", "text_area");
    },
    makeToast(variant = null, type) {
      let msg = `${this.$t("GENERAL.FEEDBACK.SUCCESS")}`;

      if (type == "text_area") {
        msg = `${this.$t("SETUP.COMMUNITY_OPTIONS.FORUMS_INTRODUCTION_UPDATED")}`;
      } else if (type == "create_forum") {
        msg = `${this.$t("SETUP.COMMUNITY_OPTIONS.FORUM_CREATED")}`;
      };
      
      this.$bvToast.toast(msg, {
        title: `${this.$t("GENERAL.FEEDBACK.SUCCESS")}`,
        variant: variant,
        solid: true
      });
    },
    configValue(value) {
      if (value == "true") {
        return 1;
      } else {
        return 0;
      };
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    }
  },
  computed: {
    ...mapGetters([
      "clientInfo",
      "projectInfo",
      "projectCommunityOptionList",
      "projectForumList",
      "projectModeratedWordList",
      "projectSegmentations"
      ]),

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
};
</script>
